(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:httpPrefix
   * @restrict A
   * @element
   *
   */
  angular
    .module('components')
    .directive('httpPrefix', httpPrefix);

  function httpPrefix() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link(scope, element, attrs, controller) {
        function ensureHttpPrefix(value) {
          if (value && !(/^(https?):\/\//i).test(value) && 'http://'.indexOf(value) !== 0 && 'https://'.indexOf(value) !== 0) {
            controller.$setViewValue('http://' + value);
            controller.$render();
            return 'http://' + value;
          }
          return value;
        }
        controller.$formatters.push(ensureHttpPrefix);
        controller.$parsers.splice(0, 0, ensureHttpPrefix);
      }
    };
  }
}());
